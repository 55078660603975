import { Project } from '../models/Project';

const projects = [
  new Project(1, 'FindSteamID.com',
    'A website that allows you to convert the unique identifier Steam assigns to their users into different formats.',
    '<p> \
    Steam by Valve Corporation has a unique string for each of their users to identify them commonly called SteamID. <br> \
    Over time, many different identifiers that derive from the same number have been added for different purposes. \
    People that create external applications need to account for many of the SteamID formats. However some applications only accept a certain format of SteamID <br> \
    Players are often asked to give a certain SteamID format and FindSteamID allows to convert a known SteamID format into any other format. <br><br> \
    FindSteamID was created as a project to learn the framework Angular, before dabbling into bigger projects. <br> \
    It utilizes Angular as it\'s front end powered by an ExpressJS backend with a MySQL database, to speed up repeated SteamID lookups. <br> \
    Other than just displaying the different SteamIDs, it also displays some basic important information per account. \
    </p>',
    ['Angular', 'ExpressJS', 'MySQL'], 'findsteamid.png', undefined, 'https://findsteamid.com'),

  // new Project(2, 'Adjuka.com',
  //   'A website that allows for game server monetization via advertisment in certain source games',
  //   '',
  //   ['Angular', 'ExpressJS', 'Sourcepawn', 'Lua', 'MySQL'], 'adjuka.png', undefined, 'https://adjuka.com'),

  new Project(3, 'TFstats.com',
    'A website that displays game data from Team Fortress 2, that has been collected from servers via external scripts',
    '',
    ['Laravel', 'SourcePawn', 'MySQL'], 'tfstats.png', undefined, 'https://tfstats.tf'),

  new Project(4, 'Blackwonder.TF',
    'The largest Team Fortress 2 server community, focused on capturing a broad audience',
    '',
    [], 'blackwonder.png', undefined, 'https://blackwonder.tf'),

  new Project(5, 'Cutiepie.TF',
    'A Team Fortress 2 server community with a focus on the same servers in different regions while remaining sustainable',
    '',
    [], 'cutiepie.png', undefined, 'https://cutiepie.tf'),

  new Project(6, 'Flux.TF',
    'A Team Fortress 2 server community with a very small and specialized group of servers, to provide quality rather than quantity',
    '',
    [], 'flux.png', undefined, 'https://flux.tf'),

  new Project(7, 'Donation Page',
    'A website that allows people to donate via PayPal or a steam trading bot to receive perks in game servers.',
    '',
    ['Vue', 'ExpressJS', 'SourcePawn', 'MySQL'], 'donation.png', undefined, 'https://donate.blackwonder.tf'),

  new Project(8, 'Reddit Meets Youtube',
    'A python script that converts top Reddit posts into YouTube videos and uploads them.',
    '',
    ['Python'], 'redditmeetsyoutube.png', 'https://github.com/BenediktKa/RedditToYoutubeAutomation', undefined),

  new Project(9, 'Team Fortress 2 AI',
    'An AI using Q-Learning and Linear Regression to create a potent 1 versus 1 bot for the game Team Fortress 2',
    '',
    ['SourcePawn', 'Python'], 'tf2ai.png', undefined, undefined),

  new Project(10, 'Graph Coloring Algorithm',
    'A university project, where a team of students tried to design the most efficient graph coloring algorithm',
    '',
    ['Java'], 'graphcoloringalgorithm.png', 'https://github.com/BenediktKa/GraphColoringCompetition', undefined),

  new Project(11, 'Graph Coloring Game',
    'A university project, where a game had to be designed around the concept of graph coloring',
    '',
    ['Java'], 'graphcoloringgame.png', 'https://github.com/BenediktKa/GraphColoringGame', undefined),

  new Project(12, 'Server Query Cacher',
    'A python script that would cache certain UDP packets from source engine games to avoid certain DDoS/DoS attacks',
    '',
    ['Python'], 'serverquerycache.png', undefined, undefined),

  new Project(13, 'Traffic Simulator',
    'A university project, where a team of students attempts to design a traffic simulator to answer certain questions',
    '',
    ['Java'], 'trafficsimulator.png', undefined, undefined),

  new Project(14, 'Block Pathfinding Simulator',
    'A university project, where a team of students attempts to design a 3D environment of blocks, to test different path finding algorithms',
    '',
    ['Java'], 'blocksimulator.png', 'https://github.com/BenediktKa/BlockSimulator', undefined),

  new Project(15, 'No Thanks AI',
    'A university project, where a team of students attempts to re-code the card game No Thanks and design AI for it',
    '',
    ['Java'], 'nothanks.png', 'https://github.com/CamielK/project2-1', undefined),

  new Project(16, 'Team Fortress 2: Store',
    'A store system for Team Fortress 2 community servers, consisiting of an easy module API, a MySQL storage and a steam bot to accept items',
    '',
    ['SourcePawn', 'MySQL', 'JavaScript'], 'store.png', undefined, undefined),

  new Project(17, 'Team Fortress 2: Class Wars',
    'A Team Fortress 2 gamemode that restricts both teams to one class.',
    '',
    ['SourcePawn'], 'classwars.png', undefined, undefined),

  new Project(18, 'Team Fortress 2: Engineer vs Medic',
    'A Team Fortress 2 gamemode that puts a team of survival engineers against a team of zombie medics.',
    '',
    ['SourcePawn', 'MySQL'], 'engineervsmedic.png', undefined, undefined),

  new Project(19, 'Team Fortress 2: Anti-Cheat',
    'An anti-cheat for Team Fortress 2, that uses real time data and statistics to detect players with third party clients.',
    '',
    ['SourcePawn', 'MySQL'], 'anticheat.png', undefined, undefined),

  new Project(20, 'Team Fortress 2: AFK Checker',
    'A Team Fortress 2 plugin that marks players as AFK and executes a configurable action.',
    '',
    ['SourcePawn'], 'afkcheck.png', undefined, undefined),

  new Project(21, 'Team Fortress 2: Server Hop',
    'A Team Fortress 2 plugin that uses the source engine UDP packet protocol to query other servers and put them in a list for players to choose from.',
    '',
    ['SourcePawn', 'MySQL'], 'serverhop.png', undefined, undefined),

  new Project(22, 'Team Fortress 2: Tower Defense',
    'A Team Fortress 2 gamemode that allows you to build towers and fight against an ever growing wave of robots',
    '',
    ['SourcePawn', 'MySQL'], 'towerdefense.png', 'https://github.com/tf2td/towerdefense', undefined),

  new Project(23, 'Team Fortress 2: Donator Welcome',
    'A Team Fortress 2 plugin that allows donators of community servers to set a custom welcome message when joining a server.',
    '',
    ['SourcePawn', 'MySQL'], 'donatorwelcome.png', undefined, undefined),

  new Project(24, 'Team Fortress 2: Team Bans',
    'A Team Fortress 2 plugin that allows players to be banned from certain teams for a definable amount of time',
    '',
    ['SourcePawn', 'MySQL'], 'teambans.png', undefined, undefined),

  new Project(25, 'Team Fortress 2: Smash random powerups',
    'A Team Fortress 2 plugin that connects into the TF2 version of Smash and places powerups at customly defined locations',
    '',
    ['SourcePawn', 'MySQL'], undefined, undefined, undefined),
];

export default projects;
