<div class="card card-project mb-5 ms-2 me-2">
  <div class="card-img-top">
    <img *ngIf="project.imageName" [src]="getProjectImagePath()">
    <div *ngFor="let tag of project.tags" class="tag">
      {{ tag }}
    </div>
  </div>
  <div class="card-body p-5">
    <h5 class="card-title text-center">{{ project.name }}</h5>
    <p class="card-text text-muted">{{ project.description }}</p>
  </div>

  <div class="card-footer">
    <div class="row text-center">
      <div *ngIf="project.githubLink" class="col">
        <a [href]="project.githubLink" target="_blank" class="btn project-btn">
          <i class="fab fa-github"></i>
          <p>Github</p>
        </a>
      </div>
      <div *ngIf="project.url" class="col">
        <a [href]="project.url" target="_blank" class="btn project-btn">
          <i class="fas fa-globe"></i>
          <p>Website</p>
        </a>
      </div>
      <div class="col">
        <a [routerLink]="['/description/', project.id]" class="btn project-btn">
          <i class="fas fa-feather"></i>
          <p>Description</p>
        </a>
      </div>
    </div>
  </div>
</div>
