<app-header [title]="'Contact'" [subtitle]="'Send me a message'"></app-header>

<div class="container">
  <div class="row">
    <div class="content-main col">
      <h5 class="content-title">Find me or contact me via the following links</h5>
      <div class="content-body">
      </div>
    </div>
  </div>
</div>
