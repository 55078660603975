import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  @Input() project: Project = new Project(0, '', '', '', []);

  constructor() {
  }

  ngOnInit(): void {
  }

  getProjectImagePath(): string {
    return './assets/img/projects/' + this.project.imageName;
  }

}
