<nav class="navbar navigation navbar-expand-lg navbar-dark text-uppercase">
    <a class="navbar-brand d-lg-none" routerLink="/"><img src="./assets/img/logo/logo-small.png"></a>
    <button class="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a class="nav-link" routerLink="/home">Home</a>
            </li>

            <a class="d-none d-lg-block ps-2 pe-2" href="#"><img src="./assets/img/logo/logo-small.png"></a>

            <li class="nav-item">
                <a class="nav-link" routerLink="/about">About</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" routerLink="/contact">Contact</a>
            </li> -->
        </ul>
    </div>
</nav>


<router-outlet></router-outlet>
