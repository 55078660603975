<app-header [title]="'About'" [subtitle]="'Background Information'"></app-header>

<div class="container">
  <div class="row">
    <div class="content-main col">
      <h5 class="content-title">A little bit of information</h5>

      <div class="content-body">

        <p>
          <img width="150" src="./assets/img/about/profile.png" align="left">
          Hi! I'm Benedikt and I am {{ calculateAge(birthDate) }} years old. <br>
          From a young age I've always been facinated by computers and there was always a project I would keep myself busy with.
          From running a podcast, to creating a YouTube channel and even coding simple websites at a very young age.
          While I was at high school, I started up a Team Fortress 2 gaming community called Blueprint.tf, which eventually turned into the
          biggest Team Fortress 2 game server community in the world now called <a href="https://blackwonder.tf/">Blackwonder.tf</a>. <br>
          Blackwonder.TF servers have been played by over 3 million gamers around the world, hosting over 300 different servers.
          A dedicated volunteer staff of up to 60 people helped manage this community. <br>
          In the mean time I required a bachelors degree in data science and knowledge engineering, with a minor in entrepreneurship. <br><br>
          My interest in programming and entrepreneurship has only grown from past experiences. I have learnt to face challenges and overcome them without external help. I really
          enjoy thinking of new ideas and improvements for anything I am currently working on and love teaching myself new programming skills. <br> <br>

          Skills that I have picked up over the years are:
        </p>

        <ul>
          <li>Programming in Python, Java, JavaScript, SourcePawn and a sprinkle of C# and PHP.</li>
          <li>Some "languages" that are not really considered programming are: CSS, HTML and SCSS, MySQL</li>
          <li>I've worked with frameworks such as: Angular, VueJS and Laravel</li>
          <li>As I have rented many dedicated servers, I know my way around the Linux operating system</li>
          <li>Some less programming related skills are: Photoshop, Video Editing in Final Cut Pro and Adobe Premiere Pro and the standard barage of Microsoft Office software but also Google's equivalent of Microsoft Office.</li>
        </ul>

        <p>
         Languages I speak include:
        </p>

        <ul>
          <li>English</li>
          <li>German</li>
          <li>Spanish</li>
          <li>Dutch</li>
        </ul>
      </div>

    </div>
  </div>
</div>
