import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import projects from 'src/app/data/projects';
import { Project } from 'src/app/models/Project';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  project = new Project(0, '', '', '', []);

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      this.project = projects[id - 1];
    });
  }

  getProjectImagePath(): string {
    return './assets/img/projects/' + this.project.imageName;
  }

}
