<app-header [title]="project.name" [subtitle]="'Project Number ' + project.id"></app-header>

<div class="container">
  <div class="row">
    <div class="content-main col">
      <h5 class="content-title">Project Description</h5>
      <div class="content-body">
        <div class="row">
          <div class="col-md-4">
            <img class="fit-image" *ngIf="project.imageName" [src]="getProjectImagePath()" align="left">
          </div>
          <div class="col-md-8">
           <div *ngIf="project.descriptionTemplate" [innerHTML]="project.descriptionTemplate"></div>
           <div *ngIf="!project.descriptionTemplate">Description coming soon!</div>
          </div>

        </div>

        <a class="btn btn-main float-start" routerLink="/">
          Back
        </a>
      </div>
    </div>
  </div>
</div>
