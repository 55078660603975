import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Dashboard
import { NavigationComponent } from './components/navigation/navigation.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { DescriptionComponent } from './components/description/description.component';

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      { path: 'home', component: HomeComponent },
      // { path: 'contact', component: ContactComponent },
      { path: 'about', component: AboutComponent },
      { path: 'description/:id', component: DescriptionComponent },
      { path: '**', redirectTo: '/home',
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
