import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';
import projects from 'src/app/data/projects';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // Math
  math = Math;

  itemsPerColumn = 3;

  projects = projects;

  constructor() { }

  ngOnInit(): void {
  }

}
