<div class="footer-decoration"></div>
<footer>
  <div class="container">

    <div class="row">

      <div class="col">
        &copy; Copyright 2021, Benedikt Kast
      </div>

      <div class="col">
        <div class="icons float-end">

          <a href="https://www.linkedin.com/in/benedikt-kast/">
            <i class="fab fa-linkedin fa-lg"></i>
          </a>

          <a href = "mailto: benedikt@blackwonder.tf">
            <i class="fas fa-envelope fa-lg"></i>
          </a>

        </div>
      </div>
    </div>

  </div>
</footer>
