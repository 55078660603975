import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  birthDate = new Date('March 25 1998');

  constructor() { }

  ngOnInit(): void {
  }

  public calculateAge(birthdate: Date): number {
      const timeDifference = Math.abs(Date.now() - new Date(birthdate).getTime());
      return Math.floor((timeDifference / (1000 * 3600 * 24)) / 365.25);
  }
}
