export class Project {
  public id: number;
  public name: string;
  public description: string;
  public descriptionTemplate: string;
  public tags: string[];
  public imageName?: string;
  public githubLink?: string;
  public url?: string;

  constructor(
    id: number, name: string, description: string, descriptionTemplate: string,
    tags: string[], imageName?: string, githubLink?: string, url?: string
  ) {
    this.id = id;
    this.name = name;
    this.descriptionTemplate = descriptionTemplate;
    this.description = description;

    this.tags = tags;

    if (imageName) {
      this.imageName = imageName;
    } else {
      // TODO: Set Default Image
    }

    this.githubLink = githubLink;
    this.url = url;
  }
}
