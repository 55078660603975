<app-header [title]="'Benedikt Kast'" [subtitle]="'Projects'"></app-header>

<div class="projects">

  <div class="container mt-5">
    <div *ngFor="let rowCount of [].constructor(math.ceil(projects.length / itemsPerColumn)); index as rowIndex" class="row row-eq-height">
      <ng-container *ngIf="projects.length - (rowIndex * itemsPerColumn) >= itemsPerColumn">
        <div *ngFor="let columnCount of [].constructor(itemsPerColumn); index as columnIndex" class="col-lg-4 col-md-6 col-sm-12">
          <app-project [project]="projects[rowIndex * itemsPerColumn + columnIndex]"></app-project>
        </div>
      </ng-container>
      <ng-container *ngIf="projects.length - (rowIndex * itemsPerColumn) < itemsPerColumn">
        <div *ngFor="let columnCount of [].constructor(projects.length - (rowIndex * itemsPerColumn)); index as columnIndex" class="col-lg-4 col-md-6 col-sm-6">
          <app-project [project]="projects[rowIndex * itemsPerColumn + columnIndex]"></app-project>
        </div>
      </ng-container>
    </div>
  </div>

</div>
